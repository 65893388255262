<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
	// @ is an alias to /src
	import tabbar from '@/components/tabbar.vue'
	export default {
	  name: 'App',
	  components: {
	    tabbar
	  }
	}
</script>
<style>
	@import url("assets/style/reset.css");
	#app{
		width: 15rem;
	}
	.btn{
		background: linear-gradient(0deg, #57BFC1 0%, #7EDEE0 100%);
		box-shadow: 0px 4px 12px 0px rgba(53,172,174,0.5), 0px 0px 8px 0px #D9E3F6;
		border-radius: 12px;
		font-size: 0.64rem;
		color:#fff;
		text-align: center;
		line-height: 1.8rem;
	}
</style>
