import axios from 'axios'
import { decrypt } from '@/utils/rsa.js'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 20000
})

// 请求拦截器
service.interceptors.request.use(
  config => {
		
    return config
  }

)

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
		if(response.config.url.indexOf('bianxianmao') === -1){
			return JSON.parse(decrypt(response.data));
		}else{
			return response.data
		}
		
  }, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  });


export default service
