import request from '@/utils/request.js'
import { encrypt } from '@/utils/rsa.js'
function getPublicData(data){
	const dataObj = {
		source:'web',
		time:new Date().getTime(),
		...data
	}
	console.log(dataObj);
	return dataObj
}
export function login(data){
	const reData = encrypt(getPublicData(data))
	return request({
	  url: '/app/login',
	  method: 'post',
	  data: reData
	})
}

export function getBannerInfo(data) {
  const reData = encrypt(getPublicData(data))
  return request({
    url: '/base/getBannerInfo',
    method: 'post',
    data: reData
  })
}

export function refreshPaymentRecord(data){
	const reData = encrypt(getPublicData(data))
	return request({
	  url: '/app/payment/refreshPaymentRecord',
	  method: 'post',
	  data: reData
	})
}

export function boxInfo(data){
	const reData = encrypt(getPublicData(data))
	return request({
	  url: '/app/boxInfo',
	  method: 'post',
	  data: reData
	})
}

export function createOrder(data){
	const reData = encrypt(getPublicData(data))
	return request({
	  url: '/app/order/createOrder',
	  method: 'post',
	  data: reData
	})
}

export function sendMsgCode(data){
	const reData = encrypt(getPublicData(data))
	return request({
	  url: '/base/sendMsgCode',
	  method: 'post',
	  data: reData
	})
}