import JSEncrypt from './jsencrypt.js'
const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAobhGH4WMwMvJRUlTxWrC
    VIOQtsHijAxPJNvAWAgq80ADpFEWrpbcGB9cKqp6XHRH4k/CVtCUZ7jm9UKwhaeA
    m18sKtcwe+M8JFNX6FSHpgde0o8C9S/QpcmLxf4iN7nGZ7P3ZTvMdmKUcdRMsVQn
    sydG2Bj6gRxP2+kexEebTeODbdM7dHlkxAL0RxGWmX/ZOBzsoWZw2gKcC0vxwyIZ
    BGHUdImG2T3nEA+VMfK2Yqv3uSYukmlKP+0mjfhrTtLFDuTV1VER9BfryBMvpQCx
    LO4pqgZnXPd+SOQcZHZ2OL0wqo5OX1+GPYx7TNxz5Qi76pK//T2mH7s6X/BuyT21
    HQIDAQAB
    -----END PUBLIC KEY-----`
export function encrypt(data) {
	let encrypt = new JSEncrypt();
	encrypt.setPublicKey(PUBLIC_KEY);
	return encrypt.encrypt(JSON.stringify(data));
};
export function decrypt(data) {
	let encrypt = new JSEncrypt();
	encrypt.setPublicKey(PUBLIC_KEY);
	return encrypt.decryptUnicodeLong(data);
};