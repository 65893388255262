<template>
  <div class="tabbar" v-if="isShow">
		<div class="tabbar-box" v-for="(item,index) in tabbarList" :key="index" @click="toPath(item.path)">
			<img :src="item.id === nowBar ? item.iconPress : item.iconDefault" alt="" class="icon-tabbar">
			<p :class="item.id === nowBar ? 'tabbar-title-press' : 'tabbar-title'">{{ item.title }}</p>
		</div>
  </div>
</template>

<script>
export default {
  name: 'Tabbar',
  data(){
		return {
			isShow:true,
			nowBar:0,
			tabbarList:[
				{
					id:0,
					title:'首页',
					iconDefault:require('../assets/images/tabbar_icon_home_default.png'),
					iconPress:require('../assets/images/tabbar_icon_home_press.png'),
					path:'/'
					
				},
				{
					id:1,
					title:'我的',
					iconDefault:require('../assets/images/tabbar_icon_my_default.png'),
					iconPress:require('../assets/images/tabbar_icon_my_press.png'),
					path:'/My'
				}
			]
		}
	},
	props:['nowBars'],
	// watch:{
	// 	'$route'(to,from){
	// 		if(to.path === '/'){
	// 			this.nowBar = 0;
	// 		}else if(to.path === '/My'){
	// 			this.nowBar = 1;
	// 		}else{
	// 			this.isShow = false
	// 		}
	// 	}
	// },
	mounted() {
		this.nowBar = this.nowBars
	},
	methods:{
		toPath(path){
			this.$router.push({path:path})
		}
	}
}
</script>

<style scoped>
	.tabbar{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 15rem;
		height: 1.96rem;
		background-color: #fff;
		position: fixed;
		bottom: 0;
		left: 0;
		box-shadow: 0px -4px 12px 0px rgba(238,222,195,0.2);
	}
	.tabbar-box{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
		text-align: center;
		font-size: 0.44rem;
	}
	.icon-tabbar{
		width: 1.08rem;
		height: 1.08rem;
	}
	.tabbar-title{
		color: #C1C6CF;
	}
	.tabbar-title-press{
		color: #141629;
	}
</style>
