<template>
  <div class="home">
    <div class="home-head">
			<p class="home-head-title">松鼠商店</p>
		</div>
		<van-tabs v-model="activeName" sticky>
		  <van-tab title="39元" name="a">
				<van-list
				  v-model="goods39.loading"
				  :finished="goods39.finished"
				  finished-text="没有更多了"
				  @load="fnBoxInfo('goods39')"
				>
					<div class="goods-list">
						<div class="goods" v-for="(item,index) in goods39.list" :key="index" @click="toDetails(item)">
							<div class="goods-img-box">
								<img :src="item.icon" alt="" class="img-goods">
							</div>
							<p class="goods-name">{{ item.goodsName }}</p>
							<p class="goods-price">￥39.00</p>
							<!-- <p class="goods-price">{{ '￥'+item.price }}</p> -->
						</div>
					</div>
				</van-list>
			</van-tab>
		  <van-tab title="49元" name="b">
				<van-list
				  v-model="goods49.loading"
				  :finished="goods49.finished"
				  finished-text="没有更多了"
				  @load="fnBoxInfo('goods49')"
				>
					<div class="goods-list">
						<div class="goods" v-for="(item,index) in goods49.list" :key="index">
							<div class="goods-img-box">
								<img :src="item.icon" alt="" class="img-goods">
							</div>
							<p class="goods-name">{{ item.goodsName }}</p>
							<p class="goods-price">￥49.00</p>
							<!-- <p class="goods-price">{{ '￥'+item.price }}</p> -->
						</div>
					</div>
				</van-list>
			</van-tab>
		  <van-tab title="59元" name="c">
				<van-list
				  v-model="goods59.loading"
				  :finished="goods59.finished"
				  finished-text="没有更多了"
				  @load="fnBoxInfo('goods59')"
				>
					<div class="goods-list">
						<div class="goods" v-for="(item,index) in goods59.list" :key="index">
							<div class="goods-img-box">
								<img :src="item.icon" alt="" class="img-goods">
							</div>
							<p class="goods-name">{{ item.goodsName }}</p>
							<p class="goods-price">￥59.00</p>
							<!-- <p class="goods-price">{{ '￥'+item.price }}</p> -->
						</div>
					</div>
				</van-list>
			</van-tab>
		</van-tabs>
		<tabbar :nowBars="0"/>
  </div>
</template>

<script>

import { List,Notify } from 'vant';
import { getBannerInfo,boxInfo } from '@/api/index.js'
import tabbar from '@/components/tabbar.vue'
export default {
  name: 'HomeView',
	components: {
	  tabbar
	},
	data(){
		return{
			bannerInfo:null,
			activeName: 'a',
			goods39:{
				list:[],
				pageNum:0,
				pageSize:20,
				total:0,
				loading:false,
				finished:false,
				boxid:'ea0e43d1b4ff468e882721794ac5c08a',
				drawId:null
			},
			goods49:{
				list:[],
				pageNum:0,
				pageSize:20,
				total:0,
				loading:false,
				finished:false,
				boxid:'0f418c8f028a4c229172d5ee7580a81c',
				drawId:null
			},
			goods59:{
				list:[],
				pageNum:0,
				pageSize:20,
				total:0,
				loading:false,
				finished:false,
				boxid:'4815cfdfb6bf43d0a939b3316e65c084',
				drawId:null
			}
		}
	},
	mounted() {
		// this.fngetBannerInfo('goods39')
	},
	methods:{
		async fngetBannerInfo(obj){
			const dataObj = {
				group: this[obj].group,
			}
			const res = await getBannerInfo(dataObj);
			console.log(res);
			if(res.errCode === 200){
				const urlArr = res.data[0].url.split("|");
				this[obj].boxid = urlArr[0]
				this[obj].drawId = urlArr[1]
			}else{
				Notify({ type: 'danger', message: res.msg });
			}
		},
		async fnBoxInfo(obj){
			console.log('obj',obj)
			if(this[obj].boxid){
				if(this[obj].pageNum * this[obj].pageSize <= this[obj].total){
					this.loading = true;
					const dataObj = {
						boxId:this[obj].boxid,
						page:this[obj].pageNum,
						size:this[obj].pageSize
					}
					const res = await boxInfo(dataObj);
					console.log('boxInfo===>',res)
					if(res.errCode === 200){
						this[obj].total = res.data.maxSize;
						this[obj].pageNum ++;
						this[obj].list = this[obj].list.concat(res.data.goodsList);
						this.loading = false;
					}else{
						Notify({ type: 'danger', message: res.msg });
						this.loading = false;
					}
				}else{
					
					this.finished = true;
					this.loading = false;
				}
			}else{
				setTimeout(()=>{
					this.fnBoxInfo(obj)
				},100)
			}
			
		},
		toDetails(item){
			const jsons = JSON.stringify(item);
			this.$router.push({path:'/details',query:item})
		}
	}
}
</script>

<style>
	.home{
		width: 15rem;
		min-height: 100vh;
	}
	.home-head{
		padding: 0.8rem 0 0 0;
	}
	.home-head-title{
		font-weight: 600;
		font-size: 0.64rem;
		color: #000;
		text-align: center;
		line-height: 1.76rem;
	}
	.goods-list{
		width: 100%;
		padding:0.4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		background-color: #F9F9F9;
	}
	.goods-list .goods{
		width: 7rem;
		background-color: #fff;
		margin-bottom: 0.24rem;
		padding-top: 0.24rem;
		padding-bottom: 0.76rem;
	}
	.goods-img-box{
		width: 7rem;
		height: 5.9rem;
		overflow: hidden;
		position: relative;
	}
	.goods-img-box .img-goods{
		max-width: 100%;
		height: 5.9rem;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin:auto;
	}
	.goods-name{
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 0.48rem;
		color:#000;
		line-height: 0.64rem;
		min-height: 1.28rem;
		padding:0 0.48rem;
		margin-top: 0.2rem;
	}
	.goods-price{
		font-size: 0.56rem;
		font-weight: 600;
		color: #000000;
		padding:0 0.48rem;
		margin-top: 0.4rem;
	}
	.van-tabs__line{
		background-color: #57BFC1 !important;
	}
</style>
