import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/My',
    name: 'my',
    component: () => import('../views/MyView.vue')
  },
	{
	  path: '/login',
	  name: 'login',
	  component: () => import('../views/login.vue')
	},
	{
	  path: '/details',
	  name: 'goodsdetails',
	  component: () => import('../views/goodsDetails.vue')
	},
	{
	  path: '/order',
	  name: 'order',
	  component: () => import('../views/order.vue')
	},
	{
	  path: '/pay',
	  name: 'pay',
	  component: () => import('../views/pay.vue')
	},
	{
	  path: '/privacy',
	  name: 'privacy',
	  component: () => import('../views/privacy.vue')
	}
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
